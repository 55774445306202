import { cn } from "~/lib/utils";
import { Image } from "./Image";
import { Calendar, Clock } from "lucide-react";

export function PostCard({ hasDarkBackground = false, post }) {
  const image = post.thumbnail?.image || post.hero?.image;
  const date = new Date(post.modified_gmt).toLocaleDateString([], {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  const time = new Date(post.modified_gmt).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  const month = new Date(post.modified_gmt).toLocaleDateString([], {
    month: "short",
  });
  const day = new Date(post.modified_gmt).toLocaleDateString([], {
    day: "2-digit",
  });

  const url = `/posts/${post.slug}`;
  return (
    <div className="card">
      {image && (
        <a href={url} className="block aspect-[4/3] bg-theme-green-2 flex-shrink-0">
          <Image src={image.url} alt="" className="object-cover object-center h-full" />
        </a>
      )}
      {!image && (
        <a href={url} className="block aspect-[4/3] bg-gray-500 flex-shrink-0">
          {/* date placeholder */}
          <div className="flex items-center justify-center h-full text-center">
            <div className="text-4xl font-semibold text-gray-100">
              <span className="uppercase">{month}</span>
              <span className="block text-6xl">{day}</span>
            </div>
          </div>
        </a>
      )}
      <h3 className="text-lg font-semibold mt-4 text-balance">
        <a href={url}>
          <span dangerouslySetInnerHTML={{ __html: post.title?.rendered ?? post.title }}></span>
        </a>
      </h3>
      <p
        className={cn(" flex flex-wrap items-center gap-3 text-sm", {
          "text-gray-700": hasDarkBackground,
          "text-white": !hasDarkBackground,
        })}
      >
        <span className="flex items-center gap-1 flex-shrink-0">
          <Calendar className="w-4" />
          {date}
        </span>
        <span className="flex items-center gap-1 flex-shrink-0">
          <Clock className="w-4" />
          {time}
        </span>
      </p>
      {/* <Link to={url} className="mt-4 block text-primary primary-link">
        Read More
      </Link> */}
    </div>
  );
}
